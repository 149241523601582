/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}

button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

h1, h2, h4, p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.popup-button {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  background: none;
  border: none;
  text-decoration: underline;
}

.popup-button:hover {
  color: #eaeaea;
}

/* Collaboration section */
.unique-collaboration-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-color: #000;
  margin-left: 10%;
}

.unique-collab-left {
  max-width: 20%;
  text-align: left;
}

.unique-left-text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  position: relative;
  left: 0;
  margin-left: 10%;
}

.unique-collab-right {
  max-width: 70%;
  text-align: left;
  margin-right: 13.5%;
}

.unique-main-heading {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

.unique-subtext {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}

.unique-contact-btn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: red;
  color:#fff;

  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}



.unique-contact-btn:hover {
  background-color: #fff;
  color: #000;
}

/* Footer section */
.unique-footer-section {
  padding: 40px 20px;
  background-color: #000;
  color: #fff;
}

.unique-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #555;
  margin-right: 10%;
}

.unique-footer-left {
  max-width: 300px;
  margin-left: 25%;
}
.footer-logo-image {
  max-width: 80px; /* Adjust the size */
  height: auto; /* Maintain aspect ratio */
}

.unique-footer-logo {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.unique-red-text {
  color: red;
  font-weight: bold;
}

.unique-contact-info {
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
}

.unique-footer-right {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
}

.unique-footer-right h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.unique-footer-right ul li {
  font-size: 16px;
}

.unique-footer-right a:hover {
  color: #eaeaea;
}

/* Social icons */
.unique-social-icons {
  text-align: center;
  margin: 20px 0;
  color: #fff;
}

.unique-social-icons a {
  font-size: 24px;
  margin: 0 10px;
  color: #fff;
  transition: color 0.3s ease;
}

.unique-social-icons a:hover {
  color: #f00;
}

/* Footer bottom section */
.unique-footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #aaa;
}

/* Popup Modal */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: rgba(255, 255, 255, 0.1); /* Light transparent white */
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  color: #000000;
  backdrop-filter: blur(10px); /* Blurs background behind popup */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for glass effect */
}



.unique-popup-close {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.popup-close:hover {
  background-color: #555;
}

.service-link {
  cursor: pointer;  /* Change cursor to pointer (hand) when hovering over the Services link */
}

.popup-button {
  cursor: pointer;  /* Change cursor to pointer for all buttons in the footer */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .unique-collaboration-container {
    flex-direction: column; /* Stack elements vertically */
    padding: 20px; /* Reduce padding for mobile */
    align-items: center; /* Center align items */
  }

  .unique-collab-left,
  .unique-collab-right {
    max-width: 100%; /* Allow full width for mobile */
    text-align: center; /* Center align text */
    margin: 0; /* Reset margin */
  }

  /* Move the unique-collab-right to the left slightly */
  .unique-collab-right {
    margin-left: -40px; /* Adjust this value as needed */
  }

  .unique-left-text {
    left: 0;
    margin-left: -40%;
  }

  .unique-main-heading {
    margin-top: 10px;
    font-size: 28px; /* Adjust heading size */
    margin-bottom: 20px; /* Add space below the heading */
  }

  .unique-subtext {
    font-size: 16px; /* Adjust subtext size */
  }

  .unique-footer-content {
    flex-direction: column; /* Stack footer items vertically */
    align-items: center; /* Center align items */
    margin-right: 0; /* Reset margin */
  }
.unique-company-links{
  margin-left: -80%;
}
.unique-contact-links{
  margin-left: 60%;
  position: absolute;
}
  .unique-footer-left {
    margin-left: 0; /* Reset margin for mobile */
    text-align: center; /* Center align footer text */
  }

  .unique-footer-right {
    margin-top: 10%;
    flex-direction: column; /* Stack footer items vertically */
    align-items: center; /* Center align items */
    max-width: 100%; /* Allow full width */
  }

  .unique-footer-right h4 {
    font-size: 16px; /* Adjust footer heading size */
  }

  .unique-footer-right ul li {
    font-size: 14px; /* Adjust footer list item size */
  }

  .unique-footer-bottom {
    font-size: 12px; /* Adjust footer bottom text size */
  }
}

@keyframes spanAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-span {
  animation: spanAnimation 0.5s ease forwards; /* Add the animation */
}
