.accordion-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  background-color: black;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.services-title {
  top: 15px;
  flex: 0 0 150px;
  padding-right: 40px;
  text-align: left;
  position: relative;
  font-size: 10px;
  color: #a8a4a4;
}

.services-title h2 {
  font-size: 20px;
  color: white;
}

.accordion {
  flex: 1;
  background-color: black;
  padding: 0 20px;
}

.accordion-item {
  border-bottom: 1px solid #444;
  padding: 10px 0;
  opacity: 0; /* Initially hidden for animation */
  transform: translateY(30px); /* Start slightly below */
  transition: all 0.6s ease-in-out;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  color: white;
}

.accordion-title:hover {
  color: #ccc;
}

.accordion-content {
  margin-top: 10px;
  font-size: 16px;
  color: #a8a4a4;
}

.accordion-content p {
  margin: 0;
}

.accordion-title span {
  font-size: 24px;
  font-weight: bold;
  color: #ccc;
}

/* Animation triggered when in view */
.accordion-item.animate {
  opacity: 1;
  transform: translateY(0); /* Slide into view */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .accordion-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .services-title {
    flex: none;
    margin-bottom: 20px;
    text-align: center;
  }
  .services-title h2{
    flex: none;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 55px;

  }

  .accordion {
    padding: 0;
    width: 100%;
  }
}
