.development-approach-container {
  padding: 40px;
  background-color: rgb(0, 0, 0);
  text-align: center;
}

.section-title {
  font-size: 24px;
  margin-bottom: 40px;
  color: #696666;
  font-family: 'Eastman Grotesque';
}

/* Rainbow Line */
.rainbow-line {
  width: 60px;
  height: 4px;
  margin: 0 auto 10px;
  background: linear-gradient(to right, indigo, rgb(246, 17, 117));
}

.development-approach-container p1 {
  color: black;
  font-size: 25px;
}

.approach-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 900px;
  margin: 0 auto 40px;
}

.approach-card {
  background-color: #e2d9d9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.5s, opacity 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(30px); /* Initial position */
}

.approach-card.animate {
  opacity: 1;
  transform: translateY(0); /* Slide into view */
}

.icon img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.click-me{
  color:rgb(110, 107, 106);
  font-family: 'Eastman Grotesque';
}

.icon svg {
  font-size: 40px;
  color: #3f51b5;
  margin-bottom: 10px;
}

.approach-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}
.our p {
  font-size: 16px;
  color: rgb(255, 255, 255);
}
.approach-card p {
  font-size: 16px;
  color: rgb(56, 54, 54);
}

/* Qwat Animation Section */
.qwat-animation {
  text-align: center;
  margin-top: 40px;
}

.big-q {
  display: inline-block; /* Make the container inline-block for better alignment */
  animation: rotate 2s linear infinite; /* Keep rotation on the container */
}

.big-q-image {
  width: 150px; /* Set a width for the image */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure block display */
  margin: 0 auto; /* Center the image */
}

.typed-text {
  font-size: 72px;
  font-weight: 700;
  color: #333;
  overflow: hidden;
  white-space: nowrap;    
  font-family: Eastman Grotesque;
}

.red {
  color: red;
}

.black {
  color: black;
}

/* Rotation animation for the big Q */
@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .typed-text {
    font-size: 36px;
    overflow: visible;
    white-space: normal;
    text-align: center;
    font-family: Eastman Grotesque;
  }

  .big-q-image {
    width: 80px; /* Adjust size for mobile */
  }

  .development-approach-container {
    padding: 20px;
  }

  .approach-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 20px; /* Adjust heading size for mobile */
  }

  .development-approach-container p1 {
    font-size: 20px; /* Adjust paragraph size for mobile */
  }
}
