.form-page {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    justify-content: space-around;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.form-title {
    text-align: center; /* Center the title */
    margin-bottom: 20px; /* Space below the title */
    color: #ffffff; /* Title color */
}

.form-container {
    text-align: center;
    padding: 20px;
    background-color: #010101; /* Fallback color */
    /* background-image: url('../assets/space.webp'), radial-gradient(circle, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
                      radial-gradient(circle, rgba(255, 255, 255, 0.15) 1px, transparent 1px),
                      radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px); */
    background-size: cover, 15px 15px, 25px 25px, 35px 35px; /* Adjust size for the image and starry effect */
    background-position: center, 0 0, 5px 10px, 10px 5px; /* Center the background image and shift positions for layered look */
    background-repeat: no-repeat; /* Prevent repeating of the background image */
    width: 50%; /* Set a specific width for the form */
}

.q-image-container {
    display: flex; /* Flexbox layout for Q and image */
    flex-direction: column; /* Stack Q and image vertically */
    align-items: center; /* Center items */
    margin-left: 20px; /* Space between form and Q image */
}

.q-image {
    width: 250px; /* Adjust the size */
    height: 250px; /* Maintain aspect ratio */
}

.q-rotation {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red; /* Set Q color to red */
    animation: rotate 2s linear infinite; /* Apply rotation animation */
    font-size: 100px; /* Set font size for the Q */
}

@keyframes rotate {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-size: 16px;
    color: #ffffff;
}

.underline-input {
    border: none; /* Remove default border */
    border-bottom: 2px solid #ccc; /* Add underline */
    width: 100%;
    font-size: 16px;
    outline: none; /* Remove default outline */
}

.underline-input:focus {
    border-bottom: 2px solid #000; /* Change underline color on focus */
}

button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: rgb(110, 107, 107);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .form-container {
        width: 90%; /* Increase width for mobile view */
        padding: 10px; /* Reduce padding */
    }

    .q-image-container {
        margin-left: 0; /* Remove margin for mobile */
        margin-top: 20px; /* Add margin on top for spacing */
        width: 100%; /* Allow image container to use full width */
        align-items: center; /* Center items */
    }

    .q-image {
        width: 200px; /* Adjust the size for mobile */
        height: 200px; /* Maintain aspect ratio */
    }

    .q-rotation {
        font-size: 80px; /* Reduce font size for mobile */
    }

    .form-group label {
        font-size: 14px; /* Reduce label font size for mobile */
    }

    .underline-input,
    button[type="submit"] {
        font-size: 14px; /* Reduce input and button font size */
    }
}
