/* General Support Container Styles */
.support-container {
    position: relative;
    text-align: center;
    padding: 20px;
    background-color: #010101;
    background-size: 15px 15px, 25px 25px, 35px 35px;
    background-position: 0 0, 5px 10px, 10px 5px;
    background-repeat: repeat;
    cursor: pointer;
    overflow: hidden;
}



.support-container h2 {
    color: #fff;
    font-size: 1.5rem;
    margin: 0;
    position: relative;
    z-index: 10;
}

/* Smoke Animation Container */
.smoke-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
}

/* Smoke Particle Style with Varying Opacity */
.smoke-particle {
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgb(255, 0, 0);
    border-radius: 50%;
    filter: blur(80px);
    animation: smoke-move 6s ease-in-out infinite;
}

/* Keyframes for smoke movement with opacity variation */
@keyframes smoke-move {
    0% {
        transform: translate(0, 0) scale(1);
        opacity: 0.6;
    }
    25% {
        transform: translate(50px, -30px) scale(1.2);
        opacity: 0.4;
    }
    70% {
        transform: translate(-30px, 50px) scale(1.4);
        opacity: 0.2;
    }
    95% {
        transform: translate(30px, -50px) scale(1.6);
        opacity: 0.1;
    }
    100% {
        transform: translate(0, 0) scale(1.8);
        opacity: 0;
    }
}


/* Flexbox Layout for Support Items */
.support-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    z-index: 10;
}

.support-item {
    margin: 20px;
    text-align: center;
}
.carousel-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.carousel-item:hover .carousel-image {
    transform: scale(1.1);
}
/* Ball Styling */


.support-item h3 {
    font-size: 1.2rem;
    margin: 0;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: left;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
    border-color: #000;
    outline: none;
}

textarea {
    resize: vertical;
    height: 100px;
}

button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #333;
}

.form-group input[type="checkbox"] {
    margin-right: 5px;
}

.form-group label[for="privacy"] {
    font-size: 12px;
    display: inline-block;
}

.form-group a {
    color: #000;
    text-decoration: underline;
}

/* Contact Form Styles */
.contact-form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.contact-form-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.contact-form-container input[type="checkbox"] {
    margin-right: 10px;
}

.contact-form-container label {
    font-size: 14px;
    color: #333;
}

.contact-form-container .privacy-policy {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
}

.contact-form-container .privacy-policy a {
    color: #000;
    text-decoration: underline;
}

/* Loading Spinner */
.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .support-items {
        justify-content: center;
        flex-wrap: wrap;
    }

    .support-item {
        flex: 0 0 calc(50% - 20px);
        margin: 10px;
    }

    .popup-content {
        width: 90%;
    }

    button[type="submit"] {
        padding: 10px;
        font-size: 14px;
    }

    #qwat{
        display: none;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        font-size: 14px;
    }
}

/*
vars
*/
:root {
    --carousel-transition-duration: 250ms;
    --carousel-transition-ease: ease-out;
    --carousel-bg-color-rgb: 0, 0, 0;
    --carousel-shadow-color-rgb: 128, 128, 128;
    --carousel-item-width: 11.5rem;
    --carousel-item-height: 17.5rem;
    --carousel-item-hover-effect: 1.075;
    --carousel-item-reflection-blur: 0.25rem;
    --carousel-item-empty-color-rgb: 255, 255, 255;
    --carousel-item-glow-color-rgb: 255, 255, 255;
    --carousel-item-glow-size: 5rem;
    --carousel-diameter: 50rem;
    --carousel-3d-perspective: 1000px;
    --carousel-3d-perspective-origin: 50% 20%;
    --carousel-control-button-width: 1.25rem;
    --carousel-control-button-height: 4rem;
    --carousel-control-color-rgb: 255, 255, 255;
    --carousel-animation-duration: 25s;
    --carousel-animation-play-state: running;
    --carousel-direction-animation-play-state: paused;
}

/*
global
*/
*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



/*
carousel
*/
.carousel {
    --_diameter: var(--carousel-diameter);
    --_radius: calc(var(--_diameter) / 2);
    --_item-width: var(--carousel-item-width);
    --_item-height: var(--carousel-item-height);
    perspective: var(--carousel-3d-perspective);
    perspective-origin: var(--carousel-3d-perspective-origin);
    width: var(--_diameter);
    height: var(--_diameter);
    margin: auto;
}

@media only screen and (max-width: 48rem) {
    .carousel {
            --_diameter: calc(var(--carousel-diameter) * 0.75);
            --_item-width: calc(var(--carousel-item-width) * 0.75);
            --_item-height: calc(var(--carousel-item-height) * 0.75);
    }
}

@media only screen and (max-width: 32rem) {
    .carousel {
            --_diameter: calc(var(--carousel-diameter) * 0.3);
            --_item-width: calc(var(--carousel-item-width) * 0.6);
            --_item-height: calc(var(--carousel-item-height) * 0.6);
    }
}

@media only screen and (max-width: 16rem) {
    .carousel {
            --_diameter: calc(var(--carousel-diameter) * 0.25);
            --_item-width: calc(var(--carousel-item-width) * 0.25);
            --_item-height: calc(var(--carousel-item-height) * 0.25);
    }
}

.carousel .carousel-control-button {
    --_width: var(--carousel-control-button-width);
    --_height: var(--carousel-control-button-height);
    z-index: 1;
    width: var(--_width);
    height: var(--_height);
    background-color: rgb(255 15 15);
    opacity: 0.2;
    transition: opacity var(--carousel-transition-duration) var(--carousel-transition-ease);
    position: absolute;
}
.carousel .carousel-control-button:hover {
    opacity: 0.4;
}
.carousel .carousel-control-button:has(input:checked) {
    opacity: 0.8;
}

.carousel .carousel-control-button input {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.carousel .carousel-control-button.left {
    clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
    top: calc(var(--_radius) - var(--_height) / 2);
    left: -50px;
}
.carousel:has(.carousel-control-button.left input:checked) {
    --carousel-direction-animation-play-state: running;
}

.carousel .carousel-control-button.right {
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    top: calc(var(--_radius) - var(--_height) / 2);
    right: -50px;
}
.carousel:has(.carousel-control-button.right input:checked) {
    --carousel-direction-animation-play-state: paused;
}

.carousel .carousel-rotation-direction {
    --_direction-animation-play-state: var(--carousel-direction-animation-play-state);
    --_z: calc(var(--_radius) * -1);
    transform: translateZ(var(--_z));
    transform-style: preserve-3d;
    animation: carousel-rotation-reverse calc(var(--carousel-animation-duration) / 2) reverse linear infinite var(--_direction-animation-play-state);
    transition: all var(--carousel-transition-duration) var(--carousel-transition-ease);
}

@keyframes carousel-rotation-reverse {
    from {transform: translateZ(var(--_z)) rotateY(0deg);}
    to {transform: translateZ(var(--_z)) rotateY(360deg);}
}

@keyframes carousel-rotation-normal {
    from {transform: rotateY(0deg);}
    to {transform: rotateY(360deg);}
}

.carousel .carousel-item-wrapper {
    transform-style: inherit;
    width: inherit;
    height: inherit;
    list-style-type: none;
    position: relative;
    animation: carousel-rotation-normal var(--carousel-animation-duration) normal linear infinite var(--carousel-animation-play-state);
    transition: all var(--carousel-transition-duration) var(--carousel-transition-ease);
}

.carousel .carousel-rotation-direction:has(.carousel-item:hover) {
    --carousel-animation-play-state: paused;
    --_direction-animation-play-state: paused;
}

.carousel .carousel-item {
    --_width: var(--_item-width);
    --_height: var(--_item-height);
    --_rotation: calc(360 / var(--_num-elements) * var(--_index) * 1deg);
    left: calc(var(--_radius) - var(--_item-width) / 2);
    top: calc(var(--_radius) - var(--_item-height) / 2);
    transform: rotateY(var(--_rotation)) translateZ(var(--_radius));
    transform-style: inherit;
    width: var(--_width);
    height: var(--_height);
    transition: all var(--carousel-transition-duration) var(--carousel-transition-ease);
    box-shadow: 0 0 var(--carousel-item-glow-size) transparent;
    position: absolute;
}

.carousel .carousel-item:hover {
    box-shadow: 0 0 var(--carousel-item-glow-size) rgb(var(--carousel-item-glow-color-rgb));
    transform: rotateY(var(--_rotation)) translateZ(calc(var(--_radius) * var(--carousel-item-hover-effect)));
}


.carousel .carousel-item a {
    display: block;
    width: inherit;
    height: inherit;
    text-indent: -9999px;
    background-color: rgba(var(--carousel-item-empty-color-rgb), 0.5);
    background-image: var(--_image-url);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: filter var(--carousel-transition-duration) var(--carousel-transition-ease);
    filter: grayscale(100%);
}

.carousel .carousel-item:hover a {
    filter: grayscale(0%);
}

.carousel .carousel-item::before {
    content: '';
    width: inherit;
    height: inherit;
    background-color: rgba(255, 255, 255, 0.3);
    background-image: linear-gradient(to top, rgba(var(--carousel-bg-color-rgb), 0.2), rgb(138 128 128 / 80%));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform-origin: center bottom;
    transform: rotateX(90deg) rotateY(180deg) translateY(100%);
    position: absolute;
    opacity: 0.5;
    filter: blur(0.25rem) grayscale(100%);
    transition: opacity var(--carousel-transition-duration);
}

.carousel .carousel-item:hover::before {
    opacity: 0.7;
}

/* Carousel Ground Shadow */
.carousel .carousel-ground {
    width: var(--_diameter);
    height: var(--_diameter);
    border-radius: 50%;
    background: radial-gradient(rgb(163 85 85 / 75%) 15%, rgb(166 62 62 / 0%) 60%);
    opacity: 0.5;
    transform: rotateX(90deg) translateZ(calc(var(--_item-height) / -2));
    position: absolute;
    transition: opacity var(--carousel-transition-duration);
}

.carousel .carousel-item-wrapper:has(.carousel-item:hover) .carousel-ground {
    opacity: 0.75;
}