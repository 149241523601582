/* Container styling for the header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: rgb(0, 0, 0);
}

.logo img {
  height: 50px;  /* Adjust the logo size */
}

.nav-links {
  list-style: none;
  margin-right: 20px;  /* Brings the "Our Blogs" link closer to the button */
}

.nav-links li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-right: 20px;
}

.contact-btn {
  background-color: red;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;  /* Adds slightly rounded corners */
}

.contact-btn:hover {
  background-color: darkred;  /* Hover effect */
}

nav {
  margin-left: auto;  /* Pushes the nav closer to the right side */
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #000000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sidebar-logo img {
  width: 90px;
  height: auto;
}

.sidebar-links {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.sidebar-links li {
  margin: 10px 0;
}

.sidebar-links a {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.close-icon {
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .desktop-contact {
    display: none;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    color: #fff;
    height: 24px;
    cursor: pointer;
  }

  .hamburger span {
    width: 100%;
    height: 3px;
    background-color: #ffffff;
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  .mobile-contact {
    text-align: center;
    background-color: red;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    margin-top: 15px;
  }
}

/* Ensure single row layout on mobile */
@media (max-width: 768px) {
  .header {
    flex-direction: row;  /* Ensure all items stay in a row */
    justify-content: space-between;  /* Distribute items evenly */
    padding: 10px 20px;  /* Adjust padding for smaller screens */
  }

  .logo img {
    height: 40px;  /* Slightly reduce logo size for mobile */
  }

  .nav-links {
    margin-right: 10px;  /* Reduce space between links and button */
  }

  .contact-btn {
    padding: 8px 15px;  /* Slightly smaller button for mobile */
  }
}
