.homepage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #000;
  color: white;
  overflow: hidden;
  padding: 1rem;
}

.title {
  font-size: 7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 1;
  margin-bottom: 0.5rem;
}

.event-info {
  font-size: 1.2rem;
  text-align: center;
  z-index: 1;
}

.event-info p {
  margin: 0.25rem 0;
}

.details,
.website-link {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
  z-index: 1;
}

.triangle-rays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.ray {
  position: absolute;
  width: 150px;
  height: 400px;
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0));
  transform-origin: top center;
  animation: ray-pulse 2s ease-in-out infinite;
}

@keyframes ray-pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 2.5rem;
    position: absolute;
    top: 180px;
  }
  .details,
  .website-link {
    font-size: 1rem;
    position:absolute;
    text-align: center;
    margin-top: 0.5rem;
    bottom:40%;
    z-index: 1;
  }
  .event-info {
    font-size: 1rem;
  }

  .event-info p {
    bottom:100%;
    margin: 0.15rem 0;
    margin-bottom: 10px;
    position: relative;
}

  .ray {
    width: 100px;
    height: 300px;
  }
}